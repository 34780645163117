import { UserServiceInterface } from '@hutsix/ngxh6';
import { Observable } from 'rxjs';

export function syncConfigFactory(user: UserServiceInterface): object {
  return {
    /**
     * Add any endpoints that need to be synced offline here
     */
    endpoints: ['/api/v1/users?pagination=false'],

    /**
     * Tasks are Observable[] and will be executed in sequence. You can perform any actions you want here.
     * The observable should return string[] of endpoints that will be added to the offline sync queue.
     */
    tasks: [
      new Observable<string[]>(observer => {
        // Tasks can access services provided via DI
        console.log(user);
        // Endpoints returned by the observer will be added to the sync queue
        observer.next(['/api/v1/examples?pagination=false']);
        // Tasks must complete
        observer.complete();
      }),

      new Observable<string[]>(observer => {
        // Tasks don't HAVE to return any values. They can just do their own thing then complete.
        observer.complete();
      }),
    ],
  };
}
