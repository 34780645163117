import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl, Validator } from '@angular/forms';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiServiceInterface, OfflineServiceInterface, SfFormView, ValueAccessorBase } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-fileupload',
    templateUrl: './sf-form-fileupload.component.html',
    styleUrls: ['./sf-form-fileupload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormFileuploadComponent extends ValueAccessorBase implements Validator, OnChanges {
    @Input() public view: SfFormView;
    @ViewChild('drpzone') drpzone;

    public uploadPreview = null;
    public uploading = false;
    public uploadProgress = 0;
    public originalValue: any = null;
    public inputValue: any = {};

    public cancelUpload$ = new ReplaySubject(0);

    public DROPZONE_CONFIG: DropzoneConfigInterface = {
        headers: {
            'ngsw-bypass': 'true',
        },
    };

    constructor(
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        @Inject('ApiService') public api: ApiServiceInterface,
        @Inject('OfflineService') public offline: OfflineServiceInterface,
    ) {
        super(cdRef, ngControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('view' in changes) {
            this.DROPZONE_CONFIG.acceptedFiles = this.view.vars?.allowed_types?.join(', ') || '';
            this.DROPZONE_CONFIG.maxFilesize = this.view.vars?.max_file_size || 50;
        }

        super.ngOnChanges(changes);
    }

    extractValueFromFormView(): any {
        const value: any = {};

        Object.keys(this.view.children).forEach(key => {
            if (this.view.children[key].vars.value) value[key] = this.view.children[key].vars.value;
        });

        this.originalValue = Object.assign({}, value);

        return value;
    }

    public onAddedFile(e): void {
        this.cancelUpload$ = new ReplaySubject(0);
        this.api
            .get({ url: '/api/upload/sign', query: { type: e.type, name: e.name }, useCache: false })
            .pipe(takeUntil(this.cancelUpload$))
            .subscribe(res => {
                const zone = this.drpzone.directiveRef.dropzone();
                e.inputs = res.data.inputs;
                zone.options.url = res.data.attributes.action;
                zone.processFile(e);
            });
    }

    public onSending(e): void {
        const file = e[0];
        const formData = e[2];

        this.uploading = true;
        this.uploadProgress = 0;

        Object.keys(file.inputs).forEach(key => {
            const value = file.inputs[key];
            formData.append(key, value);
        });
    }

    public onUploadProgress(e): void {
        this.uploadProgress = e[1];
    }

    public onUploadSuccess(e): void {
        if (!this.inputValue) {
            this.inputValue = {};
        }

        this.uploading = false;

        this.inputValue.path = e[0].xhr.getResponseHeader('Location');
        this.inputValue.filename = e[0].inputs.key;
        this.inputValue.originalFilename = e[0].name;
        this.inputValue.type = e[0].type;
        this.inputValue.size = e[0].size;
        this.inputValue.preview = null;

        this.onInputChange();
    }

    public onUploadError(e): void {
        this.cancelUpload$.next(true);
        this.uploading = false;
        this.uploadProgress = 0;
        this.drpzone.directiveRef.reset(true);
        alert('Upload Failed: ' + e[1]);
    }

    public reset(ask: boolean): void {
        if (ask && !confirm('Are you sure you want to remove this file?')) {
            return;
        }

        this.drpzone.directiveRef.reset(true);
        this.inputValue.path = null;
        this.inputValue.filename = null;
        this.inputValue.originalFilename = null;
        this.inputValue.type = null;
        this.inputValue.size = null;
        this.inputValue.preview = null;
    }

    public fieldReset(): void {
        if (!confirm('Changes will be lost. Are you sure?')) {
            return;
        }

        this.inputValue = Object.assign({}, this.originalValue);
        this.onInputChange();
    }
}
