import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SfFormFileuploadCollectionComponent } from '../../sf-form-fileupload-collection/sf-form-fileupload-collection.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-image-collection',
    templateUrl: './sf-form-image-collection.component.html',
    styleUrls: ['./sf-form-image-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormImageCollectionComponent extends SfFormFileuploadCollectionComponent {
    getImageTransformUrl(url: string): string {
        const urlObject = new URL(url);
        const path = urlObject.pathname;
        const params = 'w=300&h=300&fit=crop';
        return `https://d1mkocj6mefdjd.cloudfront.net/${path}?auto=enhance,compress,format&${params}`;
    }

    public onUploadSuccess(e): void {
        const file = e[0];

        this.uploading--;
        this.inputValue.push({
            __created: true,
            __tracking: Math.random(),
            url: file.xhr.getResponseHeader('Location'),
        });

        this.calcProgress();
        this.onInputChange();
    }

    openImage(url: string): void {
        window.open(url, '_blank');
    }
}
