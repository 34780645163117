import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SfFormSubformComponent } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-procedure',
    templateUrl: './sf-form-procedure.component.html',
    styleUrls: ['./sf-form-procedure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormProcedureComponent extends SfFormSubformComponent {}
