<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <H4>Info</H4>

        <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['firstName', 'lastName']"></sf-form>
        <sf-form name="form1" rowClasses="col-12" [view]="form.view" [(ngModel)]="form.model" [fields]="['staffData']"></sf-form>

        <hr />
        <H4>Account</H4>

        <div class="row">
            <div class="col-md-6">
                <sf-form name="form2" rowClasses="col-12" [view]="form.view" [(ngModel)]="form.model" [fields]="['email', 'role', 'locked']"></sf-form>
            </div>
            <div class="col-md-6">
                <sf-form name="form3" rowClasses="col-12" [view]="form.view" [(ngModel)]="form.model" [fields]="['plainPassword']"></sf-form>
            </div>
        </div>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            (cancel)="cancel()"
            (delete)="delete()"
            (archive)="archive($event)"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="form.error"></ngxh6-loading-or-error></ng-template>
