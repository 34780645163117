<div class="collection-container">
    <div
        *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex"
        [@fadeIn]="!!item.__created"
        class="collection-rows single"
        [class.dim]="fade === i"
        [style.max-height]="collapsedHeight + 'px'"
    >
        <form #itemForm="ngForm" ngForm>
            <div class="btn-wrapper me-2 flex-shrink-0" *ngIf="userService.isGranted('ROLE_MANAGER')">
                <button type="button" (click)="open(searchModal, i)" class="btn" [class.btn-success]="item.isMatched" [class.btn-gray]="!item.isMatched">
                    <i class="fa fa-fw fa-check" *ngIf="item.isMatched"></i>
                    <i class="fa fa-fw fa-question" *ngIf="!item.isMatched"></i>
                </button>
            </div>

            <sf-form
                *ngIf="!userService.isGranted('ROLE_MANAGER')"
                class="hidden"
                [view]="$any(view.children)[i] || view?.vars?.prototype"
                name="value"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [fields]="['rateDescription', 'cost']"
            ></sf-form>

            <div class="me-2" style="flex-basis: 50%" *ngIf="userService.isGranted('ROLE_MANAGER')">
                <sf-form
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    [renderAll]="true"
                    (ngModelChange)="onInputChange()"
                    [fields]="['rateDescription']"
                    [rowClasses]="'col-12'"
                ></sf-form>
            </div>

            <div class="flex-shrink-0 me-2" style="flex-basis: 100px" *ngIf="userService.isGranted('ROLE_MANAGER')">
                <sf-form
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    [renderAll]="true"
                    (ngModelChange)="onInputChange()"
                    [fields]="['cost']"
                    [rowClasses]="'col-12'"
                ></sf-form>
            </div>

            <div class="flex-grow-1 ms-auto">
                <sf-form
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    [renderAll]="true"
                    (ngModelChange)="onInputChange()"
                    [fields]="['description']"
                    [rowClasses]="'col-12'"
                ></sf-form>
            </div>

            <div class="flex-shrink-0 ms-2" style="flex-basis: 50px">
                <sf-form
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    [renderAll]="true"
                    (ngModelChange)="onInputChange()"
                    [fields]="['quantity']"
                    [rowClasses]="'col-12'"
                ></sf-form>
            </div>

            <sf-form
                class="hidden"
                [view]="$any(view.children)[i] || view?.vars?.prototype"
                name="value"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [fields]="['id', 'scheduledRateId', 'isMatched']"
            ></sf-form>

            <div class="btn-wrapper">
                <button
                    type="button"
                    class="btn ms-2 btn-danger"
                    *ngIf="view?.vars?.allow_delete || item?.__created"
                    (click)="$event.stopPropagation(); removeItem(i)"
                    (mouseover)="fade = i"
                    (mouseleave)="fade = null"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </form>
    </div>

    <div class="d-flex mt-1 mb-0" *ngIf="view?.vars?.allow_add">
        <button type="button" class="btn btn-gray me-2" (click)="addItem()"><i class="fa fa-plus"></i> Add Line Item</button>
        <button type="button" class="btn btn-gray" (click)="open(searchModal)" *ngIf="userService.isGranted('ROLE_MANAGER')">
            <i class="fa fa-search"></i> Search SOR
        </button>
    </div>
</div>

<ng-template #searchModal let-modal>
    <div class="modal-body">
        <ngxh6-filter
            [label]="searchCrudOpts.objectLabel"
            [key]="searchCrudOpts.queryKey"
            [className]="searchCrudOpts.apiClassName"
            [showFilter]="false"
            [showSearch]="true"
            [archivable]="true"
        ></ngxh6-filter>

        <ngxh6-crud [opts]="searchCrudOpts" [templateRef]="table"></ngxh6-crud>

        <ng-template #table let-response="response" let-crud="crud">
            <div class="table-responsive table-flush" *ngIf="response && response.data.length">
                <table class="table table-hover table-sticky-actions" [class.dim]="!c(crud).complete">
                    <thead>
                        <tr class="small">
                            <th>Code</th>
                            <th style="width: 100%; min-width: 300px">Item</th>
                            <th class="text-left" style="min-width: 90px">Current Rate<br />(ex GST)</th>
                            <th class="text-left" style="min-width: 90px">Current Rate<br />(inc GST)</th>
                            <th class="text-left" style="min-width: 90px">Rate<br />(inc GST)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of r(response).data; let i = index; trackBy: trackById">
                            <ngxh6-offline-indicator [model]="item"></ngxh6-offline-indicator>

                            <tr class="clickable-row" (click)="select(item)">
                                <td class="align-middle">{{ item.code }}</td>
                                <td class="align-middle">
                                    <small>{{ item.category }}<br /></small>
                                    {{ item.description }}
                                </td>
                                <td class="align-middle">${{ item.currentRateExGst }}</td>
                                <td class="align-middle">${{ item.currentRateIncGst }}</td>
                                <td class="align-middle">${{ item.rateIncGst }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-gray ms-auto" (click)="modal.dismiss('Cancelled')">Close</button>
    </div>
</ng-template>
