<ng-container *ngIf="user.watch | async as _user">
    <app-navbar *ngIf="_user.loggedIn"></app-navbar>

    <router-outlet></router-outlet>

    <ngxh6-requests *ngIf="showRequests"></ngxh6-requests>

    <div *ngIf="!online" class="offline-banner">
        <div class="inner">{{ user.simulating_offline ? '(Simulating) ' : '' }}Offline - Features may be unavailable</div>
    </div>
</ng-container>
