<div class="collection-container">
    <div
        *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex"
        [@fadeIn]="!!item.__created"
        [class.dim]="fade === i"
        [class.single]="singleLine"
        [class.card]="!singleLine"
        [class.collapsed]="!singleLine && collapsable && !expanded.has(i)"
        class="collection-rows"
    >
        <hr class="separator d-print-none" />

        <div
            [id]="item.id"
            class="card-header d-flex py-1 px-1 px-lg-1 d-print-none align-items-center"
            *ngIf="!singleLine"
            (click)="$event.stopPropagation(); toggleExpanded(i)"
        >
            <i class="text-danger fa fa-exclamation-triangle me-2" *ngIf="childrenValid.includes(i) && !itemForm.touched"></i>
            <span class="badge bg-success me-2" *ngIf="item.status === '30-completed'"><i class="fa fa-check"></i> done</span>
            <span class="badge bg-warning me-2" *ngIf="item.status === '25-forward'"><i class="fa fa-forward"></i> forward</span>
            <span class="badge bg-dark text-light me-2" *ngIf="item.priority">
                {{ { '20-urgent': 'Urgent', '30-routine': 'Routine', '10-immediate': 'Immediate' }[item.priority] }}
            </span>

            <ng-container *ngIf="item.previousJobCard">
                <a [routerLink]="'/jobcards/' + item.previousJobCard" target="_blank" *ngIf="splitProblem(item.problem).job">
                    <p class="m-0 text-nowrap">{{ splitProblem(item.problem).job }}</p>
                </a>
                <p class="m-0 text-nowrap">{{ splitProblem(item.problem).description }}</p>
            </ng-container>
            <p *ngIf="!item.previousJobCard" class="m-0 text-nowrap">{{ item.problem }}</p>

            <div class="flex-shrink-0 ms-auto d-flex align-items-center">
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </div>
        </div>

        <form
            [class.small]="small"
            #itemForm="ngForm"
            ngForm
            (click)="!!(collapsable && !expanded.has(i) && toggleExpanded(i)); (null)"
            [@collapse]="{ value: expanded.has(i) || !collapsable, params: { collapsed_height: 0 } }"
        >
            <div *ngIf="!singleValue" [ngClass]="!singleLine ? 'card-body p-2 p-lg-3' : 'flex-grow-1'">
                <sf-form
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    [renderAll]="true"
                    (ngModelChange)="onInputChange()"
                    [formClasses]="'collection-row row'"
                    [horizontal]="horizontal"
                    [wide]="wide"
                ></sf-form>
            </div>

            <div class="row flex-grow-1" *ngIf="singleValue">
                <sf-widget
                    rowClasses="col-12"
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    (ngModelChange)="onInputChange()"
                    [horizontal]="false"
                ></sf-widget>
            </div>

            <div *ngIf="singleLine" class="d-flex flex-column">
                <label class="form-label invisible">.</label>
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </div>
        </form>

        <ng-template #actions>
            <div class="btn btn-sm btn-gray" *ngIf="item.id" (click)="$event.stopPropagation(); modalService.history(item.id)" [class.d-none]="singleLine">
                <i class="fa fa-history"></i>
            </div>
            <div class="btn btn-sm ms-2 btn-gray expand" *ngIf="collapsable" (click)="$event.stopPropagation(); toggleExpanded(i)" [class.d-none]="singleLine">
                <i class="fa" [class.fa-expand]="!expanded.has(i)" [class.fa-compress]="expanded.has(i)"></i>
            </div>
            <div
                class="btn ms-2 btn-danger collection-remove"
                *ngIf="view?.vars?.allow_delete || item?.__created"
                (click)="$event.stopPropagation(); removeItem(i)"
                (mouseover)="fade = i"
                (mouseleave)="fade = null"
                [class.btn-sm]="!singleLine"
            >
                <i class="fa fa-trash"></i>
            </div>
        </ng-template>
    </div>
</div>

<div class="d-flex d-print-none" *ngIf="view?.vars?.allow_add">
    <button type="button" class="btn btn-gray mt-1 mb-0" (click)="addItem()"><i class="fa fa-plus"></i> {{ view?.vars?.attr?.add_label || 'Add' }}</button>
</div>
