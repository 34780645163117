import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './routes/user/login/login.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ReloadPageComponent } from './routes/reload-page/reload-page.component';

const routes: Routes = [
    { path: 'reload', component: ReloadPageComponent },
    { path: 'login', component: LoginComponent },

    // { path: 'form/:slug', canActivate: [AuthGuardService], component: FormRouteComponent },
    // { path: 'form/:slug/:id', canActivate: [AuthGuardService], component: FormRouteComponent },
    // { path: '', canActivate: [AuthGuardService], component: DashboardComponent },

    {
        path: '',
        canActivate: [AuthGuardService],
        data: {},
        loadChildren: () => import('./modules/default/default.module').then(m => m.DefaultModule),
    },
    {
        path: 'user-admin',
        loadChildren: () => import('./modules/user-admin/user-admin.module').then(m => m.UserAdminModule),
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
