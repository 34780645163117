import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: { [key: string]: Partial<CrudOptions> } = {};

/** Create custom CRUDS or override generated forms here */
const cruds: { [key: string]: CrudOptions } = {
  rates: new CrudOptions({
    objectLabel: 'Rate',
    formSlug: '',
    apiEndpoint: '/api/v1/used_materials',
    queryKey: 'lineitems',
    apiClassName: 'App\\Entity\\UsedMaterial',
    archivable: false,
    searchable: false,
    sortable: false,
    filterable: true,
  }),
  buildings: new CrudOptions({
    title: 'Manage Buildings',
    objectLabel: 'Building',
    formSlug: 'buildings',
    apiEndpoint: '/api/v1/locations',
    queryKey: 'conf-build',
    inline: true,
    archivable: true,
    searchable: true,
    apiClassName: 'App\\Entity\\Location',
    tableSpec: {
      columns: [
        { header: 'Code', value: 'code' },
        { header: 'Number, Street', value: 'streetName' },
        { header: 'Location', value: 'townCamp', type: 'objvalue', typeOption: 'name' },
      ],
    },
  }),
  locations: new CrudOptions({
    title: 'Manage Locations',
    objectLabel: 'Location',
    formSlug: 'locations',
    apiEndpoint: '/api/v1/town_camps',
    queryKey: 'conf-loc',
    apiClassName: 'App\\Entity\\TownCamp',
    inline: true,
    sortable: true,
    archivable: true,
    queryExtras: { pagination: false },
    tableSpec: {
      columns: [
        { header: 'Location', value: 'name', class: 'w-100' },
        { header: 'Type', value: 'typeLabel', class: 'text-nowrap' },
      ],
    },
  }),
  scheduled_rates: new CrudOptions({
    title: 'Manage SOR',
    objectLabel: 'Rate',
    formSlug: 'scheduled_rates',
    apiEndpoint: '/api/v1/scheduled_rates',
    queryKey: 'conf-sor',
    archivable: true,
    searchable: true,
    inline: true,
    hideHeader: true,
    apiClassName: 'App\\Entity\\ScheduledRates',
  }),
};

export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}
