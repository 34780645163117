import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CrudComponent, CrudOptions, ModalsServiceInterface, QueryServiceInterface, SfFormCollectionComponent, UserServiceInterface } from '@hutsix/ngxh6';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScheduledRateInterface } from '../../../../../../../_generated/api_interfaces/api/scheduledRate.interface';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-lineitem-collection',
    templateUrl: './sf-form-lineitem-collection.component.html',
    styleUrls: ['./sf-form-lineitem-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormLineitemCollectionComponent extends SfFormCollectionComponent {
    public searchCrudOpts: CrudOptions = {
        title: '',
        objectLabel: 'Rate',
        formSlug: 'scheduled_rates',
        apiEndpoint: '/api/v1/scheduled_rates',
        queryKey: 'sor-search',
        hideHeader: true,
        useCache: false,
        // paginateTop: false,
        apiClassName: 'App\\Entity\\ScheduledRates',
        emptyMessageOverride: 'No rates found',
        query: { archived: false, itemsPerPage: 25 },
    };

    public response: ReturnType<ScheduledRateInterface['apiScheduledRatesGetCollection']>;

    public sorModal?: NgbModalRef;

    constructor(
        cdRef: ChangeDetectorRef,
        ngControl: NgControl,
        @Inject('ModalsService') public modalService: ModalsServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public ngbModalService: NgbModal,
        public toastr: ToastrService,
    ) {
        super(cdRef, ngControl, modalService);
    }

    public r = (item: ReturnType<ScheduledRateInterface['apiScheduledRatesGetCollection']>) => item;
    public c = (item: CrudComponent) => item;

    public open(content, item = null): void {
        this.sorModal = this.ngbModalService.open(content, {
            ariaLabelledBy: 'SOR Search',
            size: 'lg',
            centered: true,
            scrollable: true,
            backdrop: true,
            keyboard: true,
        });

        this.sorModal.closed.subscribe((res: ReturnType<ScheduledRateInterface['apiScheduledRatesGetCollection']>['data'][0]) => {
            if (!res) return;

            if (item === null) {
                this.addItem({
                    // description: `${res.code}: ${res.description}`,
                    rateDescription: `${res.code}: ${res.description}`,
                    scheduledRateId: res.id,
                    cost: res.currentRateExGst,
                    isMatched: 1,
                    quantity: 1,
                });
                this.toastr.success(' ', 'Line Item Added');
            } else {
                this.inputValue[item] = {
                    // description: `${res.code}: ${res.description}`,
                    rateDescription: `${res.code}: ${res.description}`,
                    scheduledRateId: res.id,
                    cost: res.currentRateExGst,
                    isMatched: 1,
                    quantity: this.inputValue[item].quantity,
                    id: this.inputValue[item].id,
                    __tacking: this.inputValue[item].__tracking,
                    __created: this.inputValue[item].__created,
                };

                setTimeout(() => this.onInputChange(), 20);
                this.toastr.success(' ', 'Line Item Modified');
            }
        });
    }

    public select(item: ReturnType<ScheduledRateInterface['apiScheduledRatesIdGet']>['data']): void {
        this.sorModal.close(item);
    }
}
