<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <a class="navbar-brand py-2 ms-2 d-flex align-items-center" routerLink="/">
        <h5 class="m-0">Tangentyere Constructions</h5>
    </a>

    <ng-container *ngIf="userService.watch | async as _user">
        <div class="ms-auto" display="dynamic" placement="bottom-end" ngbDropdown *ngIf="_user.loggedIn">
            <button class="btn profile-button" ngbDropdownToggle (click)="update()">
                <ngxh6-usericon [user]="_user.account.user" [showTooltip]="false" class="me-0"></ngxh6-usericon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <h6 class="dropdown-header">{{ _user.account.user.firstName }} {{ _user.account.user.lastName }}</h6>

                <a class="dropdown-item" routerLink="user-admin"> <i class="fa fa-fw fa-user me-2"></i> My Profile </a>

                <a class="dropdown-item" routerLink="user-admin/access-logs" *ngIf="userService.hasRole('ROLE_ADMIN')">
                    <i class="fa fa-fw fa-plug me-2"></i> Access Log
                </a>

                <a class="dropdown-item" routerLink="user-admin/messenger-messages" *ngIf="userService.hasRole('ROLE_ADMIN')">
                    <i class="fa fa-fw fa-list me-2"></i> Queue
                </a>

                <div class="dropdown-divider"></div>
                <button class="dropdown-item warning" (click)="clearCaches()"><i class="fa fa-fw fa-trash me-2"></i> Clear Data Cache</button>

                <button class="dropdown-item danger" (click)="userService.logOut()" *ngIf="!_user.account.impersonating">
                    <i class="fa fa-fw fa-sign-out me-2"></i> Logout
                </button>

                <button class="dropdown-item danger" (click)="userService.exitImpersonation()" *ngIf="_user.account.impersonating">
                    <i class="fa fa-fw fa-sign-out me-2"></i> Exit Impersonation
                </button>

                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">App Version {{ version }}</h6>
                <h6 class="dropdown-header">Environment: {{ env }}</h6>
            </div>
        </div>
    </ng-container>
</nav>
