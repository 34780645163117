<form ngForm *ngIf="view && inputValue">
    <div class="row">
        <div class="col-12 col-md-4">
            <sf-form
                #formInput="ngModel"
                name="value3"
                [view]="view"
                [(ngModel)]="inputValue"
                [renderAll]="true"
                [fields]="['worReceived']"
                (ngModelChange)="onInputChange()"
            ></sf-form>
        </div>

        <div class="col-12 col-md-9 col-lg-8" [class.dim]="!inputValue.worReceived">
            <sf-form
                #formInput="ngModel"
                name="value4"
                [view]="view"
                [(ngModel)]="inputValue"
                [renderAll]="true"
                [fields]="['wor', 'deadline']"
                (ngModelChange)="onInputChange()"
            ></sf-form>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-4">
            <sf-form
                #formInput="ngModel"
                name="value1"
                [view]="view"
                [(ngModel)]="inputValue"
                [renderAll]="true"
                [fields]="['mrfRequired']"
                (ngModelChange)="onInputChange()"
            ></sf-form>
        </div>
        <div class="col-12 col-md-4 col-lg-4" [class.dim]="!inputValue.mrfRequired">
            <sf-form
                #formInput="ngModel"
                name="value2"
                [view]="view"
                [(ngModel)]="inputValue"
                [renderAll]="true"
                [fields]="['mrfNumber']"
                [required]="inputValue.mrfRequired"
                (ngModelChange)="onInputChange()"
            ></sf-form>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-4" *ngIf="inputValue.mrfRequired">
            <sf-form
                #formInput="ngModel"
                name="value1"
                [view]="view"
                [(ngModel)]="inputValue"
                [renderAll]="true"
                [fields]="['additionalMrfRequired']"
                (ngModelChange)="onInputChange()"
            ></sf-form>
        </div>
        <div class="col-12 col-md-4 col-lg-4" *ngIf="inputValue.additionalMrfRequired && inputValue.mrfRequired" [class.dim]="!inputValue.mrfRequired">
            <sf-form
                #formInput="ngModel"
                name="value2"
                [view]="view"
                [(ngModel)]="inputValue"
                [renderAll]="true"
                [fields]="['mrfNumber2']"
                (ngModelChange)="onInputChange()"
            ></sf-form>
        </div>
    </div>
</form>
