import reports_conf from '../_generated/ext_config/reports.json';
import { ReportOptions } from '@hutsix/ngxh6';

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getReportsConfig(): { [key: string]: any } {
  return reports_conf;
}

export function getReport(slug: ReportKeys): ReportOptions {
  return getReportsConfig()[slug];
}

declare global {
  type ReportKeys = keyof typeof reports_conf;
}
