import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ApiServiceInterface, ModalsServiceInterface, OfflineServiceInterface, SfFormCollectionComponent } from '@hutsix/ngxh6';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-fileupload-collection',
    templateUrl: './sf-form-fileupload-collection.component.html',
    styleUrls: ['./sf-form-fileupload-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormFileuploadCollectionComponent extends SfFormCollectionComponent implements OnChanges {
    @ViewChild('drpzone') drpzone;

    public uploading = 0;
    public files = {};
    public uploadProgress = 0;
    public bytesTotal = 0;
    public bytesDownloaded = 0;

    public DROPZONE_CONFIG: DropzoneConfigInterface = {
        headers: {
            'ngsw-bypass': 'true',
        },
        parallelUploads: 1,
        uploadMultiple: false,
        maxFiles: null,
    };

    constructor(
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        @Inject('ModalsService') public modalService: ModalsServiceInterface,
        @Inject('ApiService') public api: ApiServiceInterface,
        @Inject('OfflineService') public offline: OfflineServiceInterface,
    ) {
        super(cdRef, ngControl, modalService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('view' in changes) {
            this.DROPZONE_CONFIG.acceptedFiles = this.view.vars?.allowed_types?.join(', ') || '';
            this.DROPZONE_CONFIG.maxFilesize = this.view.vars?.max_file_size || 100;
        }

        super.ngOnChanges(changes);
    }

    cancelUpload(): void {
        this.drpzone.directiveRef.reset(true);
    }

    public onAddedFile(e): void {
        this.api.get({ url: '/api/upload/sign', query: { type: e.type, name: e.name }, useCache: false }).subscribe(res => {
            const zone = this.drpzone.directiveRef.dropzone();
            e.inputs = res.data.inputs;
            zone.options.url = res.data.attributes.action;
            zone.processFile(e);
        });
    }

    public onSending(e): void {
        const file = e[0];

        this.uploading++;
        this.files[file.inputs.key] = {
            size: file.upload.total,
            downloaded: file.upload.bytesSent,
            progress: file.upload.progress,
        };

        Object.keys(file.inputs).forEach(key => {
            e[2].append(key, e[0].inputs[key]);
        });

        this.calcProgress();
    }

    public onUploadProgress(e): void {
        const file = e[0];
        this.files[file.inputs.key].size = file.upload.total;
        this.files[file.inputs.key].downloaded = file.upload.bytesSent;
        this.files[file.inputs.key].progress = file.upload.progress;
        this.calcProgress();
    }

    public onUploadSuccess(e): void {
        const file = e[0];

        this.uploading--;
        this.inputValue.push({
            __created: true,
            __tracking: Math.random(),
            path: file.xhr.getResponseHeader('Location'),
            filename: file.inputs.key,
            originalFilename: file.name,
            type: file.type,
            size: file.upload.total,
            preview: null,
        });

        this.calcProgress();
        this.onInputChange();
    }

    public onUploadError(e): void {
        const file = e[0];
        delete this.files[file.inputs.key];
        this.uploading--;

        alert('Upload Failed: ' + e[1]);
        this.calcProgress();
    }

    calcProgress(): void {
        this.bytesTotal = 0;
        this.bytesDownloaded = 0;

        if (this.uploading < 1) {
            this.files = [];
            this.uploading = 0;
        }

        Object.keys(this.files).forEach(k => {
            this.bytesTotal += this.files[k].size;
            this.bytesDownloaded += this.files[k].downloaded;
        });

        this.uploadProgress = (this.bytesDownloaded / this.bytesTotal) * 100;
    }
}
