import { DefaultFilterComponent, DefaultFormComponent, FormOptions } from '@hutsix/ngxh6';
import { UserFormComponent } from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import forms_conf from '../_generated/ext_config/forms.json';
import {JobcardFormComponent} from "../modules/_shared/components/forms/custom-forms/jobcard-form/jobcard-form.component";

export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  UserFormComponent: UserFormComponent,
  JobcardFormComponent: JobcardFormComponent,
};

/** Override generated form values here */
const forms_conf_overrides: { [key: string]: Partial<FormOptions> } = {};

/** Create custom forms or override generated forms here */
const forms = {
  user: new FormOptions({
    object_name: 'User',
    api_get: '/api/user/form',
    api_refresh_endpoint: '/api/v1/users',
    offline_readonly: true,
    allow_archive: true,
    object_class: 'App\\Entity\\User',
    success_route: 'users',
    component: 'UserFormComponent',
  }),
  // notes: new FormOptions({
  //   object_name: 'Note',
  //   api_get: 'notes/form',
  //   api_refresh_endpoint: '/api/v1/note_lists',
  //   offline_create: true,
  //   offline_edit: true,
  //   success_route: null,
  // }),
  profile: new FormOptions({
    object_name: 'Profile',
    route: 'profile',
    offline_readonly: true,
    api_get: '/api/user/profile',
    create_string: 'Edit Profile',
    success_route: 'profile',
  }),
  jobcard: new FormOptions({
    object_name: 'Job',
    api_refresh_endpoint: '/api/v1/job_cards',
    offline_edit: true,
    offline_create: true,
    allow_archive: true,
    object_class: 'App\\Entity\\JobCard',
    success_route: 'jobcards/{id}',
    component: 'JobcardFormComponent',
  }),
  buildings: new FormOptions({
    object_name: 'Building',
    allow_archive: true,
    object_class: 'App\\Entity\\Location',
    api_refresh_endpoint: '/api/v1/locations',
    success_route: 'config',
    modal_size: 'lg',
  }),
  locations: new FormOptions({
    object_name: 'Location',
    allow_archive: true,
    object_class: 'App\\Entity\\TownCamp',
    api_refresh_endpoint: '/api/v1/town_camps',
    success_route: 'config',
    modal_size: 'lg',
  }),
  scheduled_rates: new FormOptions({
    object_name: 'Rate',
    allow_archive: true,
    object_class: 'App\\Entity\\ScheduledRate',
    api_refresh_endpoint: '/api/v1/scheduled_rates',
    success_route: 'config',
    modal_size: 'lg',
  }),
  sor_import: new FormOptions({
    object_name: 'SOR Import',
    allow_edits: false,
    api_get: '/api/sor_import',
    api_refresh_endpoint: '/api/v1/scheduled_rates',
    modal_size: 'lg',
  }),
  wor: new FormOptions({
    object_name: 'Work Order Request',
    allow_edits: true,
    allow_archive: false,
    object_class: 'App\\Entity\\WorkOrderRequest',
    api_refresh_endpoint: '/api/v1/work_order_requests',
    modal_size: 'xxl',
    small_fields: true,
  }),
};

export function getFormsConfig(): { [key: string]: FormOptions } {
  const f = JSON.parse(JSON.stringify(forms_conf));
  for (const [k, v] of Object.entries(forms_conf_overrides)) {
    if (f[k]) {
      f[k] = Object.assign(forms_conf[k], v);
    } else {
      console.warn(`Attempt to override generated form failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...f, ...forms };
}

export function getForm(slug: FormKeys): FormOptions {
  return getFormsConfig()[slug];
}

declare global {
  type FormKeys = keyof typeof forms | keyof typeof forms_conf;
}
