import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { fadeInOut, fadeInOut2 } from '../../../../../../animations';
import { ApiServiceInterface, DefaultFormComponent, FormServiceInterface, NavItem, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModelGroup } from '@angular/forms';

@Component({
    selector: 'app-jobcard-form',
    templateUrl: './jobcard-form.component.html',
    styleUrls: ['./jobcard-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobcardFormComponent extends DefaultFormComponent implements AfterViewInit, OnInit, OnChanges {
    @ViewChildren('informationForm') informationForm: QueryList<NgModelGroup>;
    @ViewChildren('filesForm') filesForm: QueryList<NgModelGroup>;
    @ViewChildren('tasksForm') tasksForm: QueryList<NgModelGroup>;

    public navItems: NavItem[] = [];
    recentJobs: any;
    public viewRecentJobs = false;

    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        @Inject('ApiService') private api: ApiServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        public toastr: ToastrService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        super(formService, userService, location, cdRef);
    }

    ngAfterViewInit(): void {
        this.navItems = [
            { id: 'tasks', label: 'Tasks', destroyOnHide: false, watch: this.tasksForm },
            {
                id: 'information',
                label: 'Information',
                destroyOnHide: false,
                watch: this.informationForm,
            },
            {
                id: 'files',
                label: 'Files',
                destroyOnHide: false,
                watch: this.filesForm,
                roles: ['ROLE_MANAGER'],
            },
        ];
    }
    viewLastFourJobsByLocation(location) {
        this.api.get({ url: `/api/jobcard/byLocation/${location}/last_four_jobs`, useCache: false }).subscribe((res: any) => {
            this.recentJobs = res.data;
            this.viewRecentJobs = true;
            this.cdRef.detectChanges();
        });
    }
    hideLastFourJobsByLocation() {
        this.viewRecentJobs = false;
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        if (!this.form.editId && !this.form.model?.reportedToUser) {
            this.form.model.reportedToUser = this.userService.user;
        }

        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (this.userService.isGranted('ROLE_HMO')) this.options.show_archive = false;
    }
}
