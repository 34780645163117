<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <ng-container *ngIf="!form.editId">
            <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['location', { field: 'priority', classes: 'col-md-3' }]"></sf-form>
            <div class="col-1" *ngIf="form.model.location && !viewRecentJobs">
                <div class="btn btn-sm btn-warning" (click)="viewLastFourJobsByLocation(form.model.location.id)">View latest jobs</div>
            </div>
            <div class="col-1" *ngIf="form.model.location && viewRecentJobs">
                <div class="btn btn-sm btn-danger" (click)="hideLastFourJobsByLocation()">Hide latest jobs</div>
            </div>
            <ng-container *ngIf="viewRecentJobs">
                <table *ngIf="recentJobs && recentJobs?.length">
                    <tr *ngFor="let job of recentJobs">
                        <td
                            class="badge rounded-pill"
                            [class.bg-dark]="job?.status?.includes('10')"
                            [class.bg-warning]="job?.status?.includes('14') || job?.status?.includes('16')"
                            [class.bg-danger]="job?.status?.includes('25')"
                            [class.bg-light]="job?.status?.includes('20') || job?.status?.includes('28') || job?.status?.includes('18')"
                            [class.text-dark]="job?.status?.includes('20') || job?.status?.includes('28') || job?.status?.includes('18')"
                            [class.bg-success]="job?.status?.includes('30')"
                            [ngbTooltip]="job?.status + (job?.verified ? ' & Verified' : '')"
                        >
                            {{ job?.statusLabel }}
                            <i class="fa fa-fw fa-check" *ngIf="job?.verified"></i>
                        </td>
                        <td>
                            <a [routerLink]="'/jobcards/' + job.id" target="_blank">Job #{{ job.nbr }}</a>
                        </td>
                        <td>(Notified: {{ job.requestDate | str2Date | date: 'shortDate' }})</td>
                    </tr>
                </table>
                <p *ngIf="recentJobs && !recentJobs?.length">No previous Jobs</p>
            </ng-container>
            <sf-form
                name="form2"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="['reportedToUser', 'notificationMethod', 'tenantName']"
                rowClasses="col-md-4"
            ></sf-form>
            <sf-form name="form3" [view]="form.view" [(ngModel)]="form.model" [fields]="['tasks']"></sf-form>
            <sf-form
                name="form4"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="['requestDate', 'status']"
                class="invisible position-absolute"
            ></sf-form>
        </ng-container>

        <ng-container *ngIf="form.editId">
            <sf-form name="form" [view]="form.view" [(ngModel)]="form.model" [fields]="['location', 'status', 'priority']"></sf-form>
            <div class="col-1" *ngIf="form.model.location && !viewRecentJobs">
                <div class="btn btn-sm btn-warning" (click)="viewLastFourJobsByLocation(form.model.location.id)">View latest jobs</div>
            </div>
            <div class="col-1" *ngIf="form.model.location && viewRecentJobs">
                <div class="btn btn-sm btn-danger" (click)="hideLastFourJobsByLocation()">Hide latest jobs</div>
            </div>
            <ng-container *ngIf="viewRecentJobs">
                <table *ngIf="recentJobs && recentJobs?.length">
                    <tr *ngFor="let job of recentJobs">
                        <td
                            class="badge rounded-pill"
                            [class.bg-dark]="job?.status?.includes('10')"
                            [class.bg-warning]="job?.status?.includes('14') || job?.status?.includes('16')"
                            [class.bg-danger]="job?.status?.includes('25')"
                            [class.bg-light]="job?.status?.includes('20') || job?.status?.includes('28')"
                            [class.text-dark]="job?.status?.includes('20') || job?.status?.includes('28')"
                            [class.bg-success]="job?.status?.includes('30')"
                            [ngbTooltip]="job?.status + (job?.verified ? ' & Verified' : '')"
                        >
                            {{ job?.statusLabel }}
                            <i class="fa fa-fw fa-check" *ngIf="job?.verified"></i>
                        </td>
                        <td>
                            <a [routerLink]="'/jobcards/' + job.id" target="_blank">Job #{{ job.nbr }}</a>
                        </td>
                        <td>(Notified: {{ job.requestDate | str2Date | date: 'shortDate' }})</td>
                    </tr>
                </table>
                <p *ngIf="recentJobs && !recentJobs?.length">No previous Jobs</p>
            </ng-container>
            <sf-form
                name="form2"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="['declinedReason']"
                [class.hidden]="form.model.status !== '25-declined'"
            ></sf-form>

            <hr />

            <h5>Information</h5>
            <div
                class="alert verified_alert d-print-none"
                [class.alert-danger]="!form.model['verified']"
                [class.alert-success]="form.model['verified']"
                *ngIf="form.view.children['verified']"
            >
                <sf-form #managementForm name="managementForm2" [view]="form.view" [(ngModel)]="form.model" [fields]="['verified']"></sf-form>
            </div>

            <sf-form
                #informationForm
                name="informationForm"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="['reportedToUser', 'requestDate', 'notificationMethod', 'tenantName']"
            ></sf-form>

            <div class="row d-print-none">
                <div class="col-12 col-lg-9">
                    <sf-form class="d-flex" name="informationForm2" [view]="form.view" [(ngModel)]="form.model" [fields]="['procedure']"></sf-form>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <sf-form #informationForm name="informationForm3" [view]="form.view" [(ngModel)]="form.model" [fields]="['bizpracId']"></sf-form>
                </div>
            </div>

            <div class="d-print-none" *ngIf="form.view.children['files']">
                <hr />
                <h5>Files</h5>
                <sf-form #filesForm name="filesForm" [view]="form.view" [(ngModel)]="form.model" [fields]="['files']"></sf-form>
            </div>

            <hr />

            <h5>Tasks</h5>
            <!--      <div class="alert verified_alert" [class.alert-danger]="!form.model.cost?.approved" [class.alert-success]="form.model.cost?.approved"-->
            <!--           [class.hidden]="!form.view.children['cost'] || !form.model.cost?.estimatedCost || form.model.cost?.estimatedCost === '0.00'"-->
            <!--      >-->
            <!--        <sf-form name='form4' [view]="form.view" [(ngModel)]="form.model" [fields]="['cost']"></sf-form>-->
            <!--      </div>-->

            <sf-form #tasksForm name="tasksForm" [view]="form.view" [(ngModel)]="form.model" [fields]="['tasks']"></sf-form>
        </ng-container>

        <ng-container *ngIf="!readonly">
            <hr *ngIf="!options.sticky_actions && options.actions_separator" />
            <ngxh6-form-actions
                [form]="form"
                [options]="options"
                [formRef]="formRef"
                [valid]="formRef.valid"
                (cancel)="cancel()"
                (delete)="delete()"
                (archive)="archive($event)"
            ></ngxh6-form-actions>
        </ng-container>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="form.error"></ngxh6-loading-or-error></ng-template>
