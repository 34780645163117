import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfFormDateComponent } from './components/forms/custom-fields/sf-form-date/sf-form-date.component';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { SfFormFileuploadComponent } from './components/forms/custom-fields/sf-form-fileupload/sf-form-fileupload.component';
import { MomentModule } from 'ngx-moment';
import { SfFormSelectImagegridComponent } from './components/forms/custom-fields/sf-form-select-imagegrid/sf-form-select-imagegrid.component';
import { UserFormComponent } from './components/forms/custom-forms/user-form/user-form.component';
import { SfFormDatetimeComponent } from './components/forms/custom-fields/sf-form-datetime/sf-form-datetime.component';
import { SfFormTimeComponent } from './components/forms/custom-fields/sf-form-time/sf-form-time.component';
import { Ngxh6Module, SfFormModule } from '@hutsix/ngxh6';
import { SfFormSelectIconComponent } from './components/forms/custom-fields/sf-form-select-icon/sf-form-select-icon.component';
import { JobcardFormComponent } from './components/forms/custom-forms/jobcard-form/jobcard-form.component';
import { SfFormImageCollectionComponent } from './components/forms/custom-fields/jobcard/sf-form-image-collection/sf-form-image-collection.component';
import { SfFormTaskCollectionComponent } from './components/forms/custom-fields/jobcard/sf-form-task-collection/sf-form-task-collection.component';
import { SfFormFileuploadCollectionComponent } from './components/forms/custom-fields/sf-form-fileupload-collection/sf-form-fileupload-collection.component';
import { SfFormLineitemCollectionComponent } from './components/forms/custom-fields/jobcard/sf-form-lineitem-collection/sf-form-lineitem-collection.component';
import { SfFormProcedureComponent } from './components/forms/custom-fields/jobcard/sf-form-procedure/sf-form-procedure.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NavbarComponent } from './components/ui/navbar.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    url: '#',
    maxFilesize: 50,
    // acceptedFiles: '',
    maxFiles: 1,
    addRemoveLinks: true,
    autoQueue: false,
    autoProcessQueue: false,
    uploadMultiple: false,
    headers: {
        'Cache-Control': '',
        'X-Requested-With': '',
    },
    method: 'post',
};

@NgModule({
    declarations: [
        NavbarComponent,
        SfFormDateComponent,
        SfFormFileuploadComponent,
        SfFormSelectImagegridComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        SfFormSelectIconComponent,
        UserFormComponent,
        JobcardFormComponent,
        // SfFormEntity2Component,
        SfFormImageCollectionComponent,
        SfFormTaskCollectionComponent,
        SfFormFileuploadCollectionComponent,
        SfFormLineitemCollectionComponent,
        SfFormProcedureComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        // To be exported
        Ngxh6Module,
        NgPipesModule,
        QuillModule,
        NgbModule,
        SfFormModule.forRoot({
            customTypes: [
                // { prefix: 'search-entity', component: SfFormEntity2Component },
                {
                    prefix: 'fileupload-collection',
                    component: SfFormFileuploadCollectionComponent,
                    rowClasses: 'col-12',
                    showLabel: false,
                },
                {
                    prefix: 'image-collection',
                    component: SfFormImageCollectionComponent,
                    rowClasses: 'col-12',
                },
                {
                    prefix: 'task-collection',
                    component: SfFormTaskCollectionComponent,
                    rowClasses: 'col-12',
                    showLabel: false,
                },
                {
                    prefix: 'lineitem-collection',
                    component: SfFormLineitemCollectionComponent,
                    rowClasses: 'col-12',
                },
                { prefix: 'date', component: SfFormDateComponent },
                { prefix: 'datetime', component: SfFormDatetimeComponent },
                { prefix: 'time', component: SfFormTimeComponent },
                { prefix: 'file_upload', component: SfFormFileuploadComponent },
                {
                    prefix: 'job_card_procedure',
                    component: SfFormProcedureComponent,
                    rowClasses: 'col-12',
                    showLabel: false,
                },
            ],
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            newestOnTop: false,
            enableHtml: false,
            // timeOut: 1000,
        }),
        VirtualScrollerModule,
        DragDropModule,
        DropzoneModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
            },
        }),
    ],
    exports: [
        // Declared
        NavbarComponent,
        SfFormDateComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        SfFormFileuploadComponent,
        SfFormSelectImagegridComponent,
        SfFormSelectIconComponent,
        // SfFormEntity2Component,
        SfFormImageCollectionComponent,
        SfFormTaskCollectionComponent,
        SfFormFileuploadCollectionComponent,
        SfFormLineitemCollectionComponent,
        SfFormProcedureComponent,

        // IMPORTS:
        Ngxh6Module,
        NgPipesModule,
        QuillModule,
        NgbModule,
        SfFormModule,
        VirtualScrollerModule,
        DragDropModule,
        ToastrModule,
        ToastrModule,
        MomentModule,
    ],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ],
})
export class SharedModule {}
