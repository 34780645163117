<div class="collection-container row d-print-none">
    <div class="dz-container col-4 col-md-3 col-xl-2 d-flex" *ngIf="view?.vars?.allow_add">
        <dropzone
            #drpzone
            class="btn btn-gray w-100"
            [useDropzoneClass]="false"
            [message]="'Drop files or click here to add Images'"
            (uploadProgress)="onUploadProgress($event)"
            (error)="onUploadError($event)"
            (addedFile)="onAddedFile($event)"
            (sending)="onSending($event)"
            (success)="onUploadSuccess($event)"
            [config]="DROPZONE_CONFIG"
        >
        </dropzone>
    </div>

    <div
        *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex"
        [@fadeIn]="!!item.__created"
        [class.dim]="fade === i"
        class="col-4 col-md-3 col-xl-2 position-relative"
    >
        <form #itemForm="ngForm" ngForm class="removable">
            <sf-form
                *ngIf="!singleValue"
                [view]="$any(view.children)[i] || view?.vars?.prototype"
                name="value"
                [(ngModel)]="inputValue[i]"
                [renderAll]="true"
                (ngModelChange)="onInputChange()"
                [formClasses]="'collection-row row'"
                [horizontal]="false"
                class="position-absolute invisible"
            ></sf-form>

            <div class="image">
                <a *ngIf="item.url" [href]="item.url" (click)="openImage(item.url)" target="_blank">
                    <img [src]="getImageTransformUrl(item.url)" alt="Image" />
                </a>
            </div>

            <div class="collection-remove-wrapper">
                <button
                    type="button"
                    class="btn ms-2 btn-danger collection-remove"
                    *ngIf="view?.vars?.allow_delete || item?.__created"
                    (click)="removeItem(i)"
                    (mouseover)="fade = i"
                    (mouseleave)="fade = null"
                >
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </form>
    </div>

    <div class="col-4 col-md-3 col-xl-2" *ngIf="uploading > 0">
        <div class="progress mt-2">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [ngStyle]="{ width: uploadProgress + '%' }"></div>
        </div>
        <div class="d-flex mt-1 align-items-center">
            <small
                >Uploading {{ uploading }} {{ 'file' | makePluralString: uploading }}.<br />{{ this.bytesDownloaded | bytes: 1 }}/{{
                    this.bytesTotal | bytes: 1
                }}</small
            >
            <div class="btn btn-sm btn-danger ms-auto" (click)="cancelUpload()">Cancel</div>
        </div>
    </div>
</div>
