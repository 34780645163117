import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormServiceInterface, ModalsServiceInterface, OfflineServiceInterface, SfFormCollectionComponent } from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-task-collection',
    templateUrl: './sf-form-task-collection.component.html',
    styleUrls: ['./sf-form-task-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormTaskCollectionComponent extends SfFormCollectionComponent implements OnInit {
    constructor(
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        @Inject('ModalsService') public modalService: ModalsServiceInterface,
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('OfflineService') public offlineService: OfflineServiceInterface,
        public route: ActivatedRoute,
    ) {
        super(cdRef, ngControl, modalService);
    }
    splitProblem(problem: string): { job: string; description: string } {
        let result = { job: '', description: '' };

        const regex = /\(From Job #\d+\)/;
        const match = problem.match(regex);

        if (match) {
            result.job = match[0];
            result.description = problem.replace(regex, '').trim();
        } else {
            result.description = problem;
        }

        return result;
    }
}
